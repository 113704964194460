import { createEffect, splitProps } from "solid-js";
import { SubmitButtonProps } from "./model";
import { A } from "@solidjs/router";

export function SubmitButton(props: SubmitButtonProps) {
  const [local, others] = splitProps(props, ["children", "disabled"]);
  return (
    <A href={!local.disabled && props.link ? props.link : ""}>
      <button
        {...others}
        disabled={local.disabled}
        class={`disabled:bg#disabled bg#p w-88px h-44px text#n-50 text-16px cursor-pointer rounded-sm`}
        type="submit"
      >
        {local.children}
      </button>
    </A>
  );
}
